<script setup>
import { Link } from '@inertiajs/vue3';
import { inject } from 'vue';

// Components
import Icon from '@/Components/Icon.vue';

// Icons
import calendar from '@i/calendar-pink.svg';
import clock from '@i/clock-pink.svg';

const route = inject('route');

const props = defineProps({
    shift: Object,
    functionChef: String,
    companyName: String,
    location: String,
    startTime: String,
    endTime: String,
});
const dayjs = inject('dayjs');

const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
};
</script>

<template>
    <div class="p-4 mb-4 rounded-lg bg-blue-medium md:px-6 md:py-8">
        <Link class="cursor-pointer group" :href="route('shifts.show', { shift: props.shift.id })">
            <div class="flex items-center">
                <div class="w-full">
                    <ul>
                        <li class="flex items-center" v-if="shift.position_name">
                            <span
                                class="text-sm font-semibold leading-5 uppercase transition-colors md:text-2xl group-hover:text-pink"
                                >{{ shift.position_name }}</span
                            >
                        </li>
                        <li
                            class="flex items-center mb-2 md:mb-4"
                            v-if="shift.location.name || shift.location.address.city"
                        >
                            <span
                                class="text-sm font-semibold uppercase transition-colors md:text-2xl group-hover:text-pink"
                            >
                                {{ shift.location.name }} {{ shift.location.address.city }}
                            </span>
                        </li>
                        <li class="flex gap-8 leading-4 text-blue-lighter" v-if="shift.start && shift.end">
                            <div class="flex items-center gap-2">
                                <Icon :icon="calendar" />

                                <span class="text-sm md:text-base">
                                    {{ dayjs(shift.start).format('dddd DD MMMM YYYY') }}
                                </span>
                            </div>

                            <div class="flex items-center gap-2">
                                <Icon :icon="clock" />

                                <span class="text-sm md:text-base">
                                    {{ dayjs(shift.start).format('HH:mm') }} - {{ dayjs(shift.end).format('HH:mm') }}
                                </span>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </Link>
    </div>
</template>
